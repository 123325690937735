<template>
    <div :class = "[
        'basic-information-inputs-view',
        {'has-shipping-address': address_needed!=false}
    ]">
        <div class = 'basic-information-view-title'>
            {{ basic_information_view_title_content }}
        </div>
        <div class = 'input-note'>
            (To change your basic information, please contact us at 866-364-0963)
        </div>
        <div class = "all-units-wrapper">
            <div v-for = 'input_unit_info in basic_information_inputs_structure_data'
            :key = "`${input_unit_info.input_id}-badic-information-input-unit`"
            :class = "input_unit_info.unit_wrapper_classname">
                <ShippingAddressInputUnit 
                v-model = 'current_shipping_address_data[input_unit_info.input_id]' 
                v-bind = 'input_unit_info' :disabled = "conditionalDisableInput(input_unit_info)"/>
            </div>
        </div>
    </div>
</template>

<script>
import ShippingAddressInputUnit from '@/components/shippingAddress/ShippingAddressInputUnit.vue';
import { basic_information_inputs_structure_data } from "@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js";
import { mapState } from 'pinia';
import { useShippingAddressStore } from "@/stores/ShippingAddressStore.ts";
export default{
    components:{
        ShippingAddressInputUnit,
    },
    data(){
        return{
            basic_information_inputs_structure_data,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'previous_shipping_address_data',
            'current_shipping_address_data',
            'address_needed',
        ]),
        incomplete_basic_information_in_db(){
            return !this.previous_shipping_address_data.name || ! this.previous_shipping_address_data.birthdate || !this.previous_shipping_address_data.gender;
        },
        basic_information_view_title_content(){
            return this.incomplete_basic_information_in_db ? "Please Input Your Basic Information" : "Basic Information";
        },
    },
    methods:{
        conditionalDisableInput(input_unit_info){
            //if the structure indicates the disabled field is true, it means that this field is always disabled
            if(input_unit_info.disabled){
                return true;
            }else{
                //if birthdate or gender field has value originally, disable it.
                if((input_unit_info.input_id == "birthdate" && this.previous_shipping_address_data['birthdate']) || (input_unit_info.input_id == "gender" && this.previous_shipping_address_data['gender'])){
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped>
.basic-information-inputs-view{
    margin-top:40px;
    padding-left:314px;
    padding-right:318px;
    min-height: 100%;
}
.basic-information-view-title{
    padding-bottom:12px;
    width:100%;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.input-note{
    color:#BCCCDC;
    padding-bottom: 12px;
}
.all-units-wrapper{
    width:100%;
    padding-bottom: 36px;

    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    row-gap:16px;
    column-gap:18px;
}
.has-shipping-address .all-units-wrapper{
    border-bottom: 1px solid #BCCCDC;
}

/* unit wrapper width */
.Name-unit-wrapper,
.DOB-unit-wrapper,
.Gender-unit-wrapper,
.Phone-unit-wrapper{
    width:48%;
}
.save-btn{
    width:100%;
    padding:8px 0px;
    background: #0E4672;
    border-radius: 4px;

    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.429px;
    cursor:pointer;
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 1260px){
    .basic-information-inputs-view{
        padding-left:157px;
        padding-right:159px;
        margin-top:40px;
    }
}
@media only screen and (max-width: 767px){
    .basic-information-inputs-view{
        padding-left:28px;
        padding-right:27px;
        margin-top:36px;
    }
    .basic-information-view-title{
        padding-bottom: 16px;
        width: 100%;

        color: #0E4672;
        font-family:'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }
    .all-units-wrapper{
        width:100%;
        margin-top:12px;
        padding-bottom: 24px;

        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        row-gap:8px;
        column-gap:18px;
    }

    .Name-unit-wrapper,
    .DOB-unit-wrapper,
    .Gender-unit-wrapper,
    .Phone-unit-wrapper{
        width:100%;
    }
}
</style>