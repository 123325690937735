<template>
  <div id="page-container" v-show="showSucceedPage">
    <div class="pageBodyContainer">
      <img
        class="paymentStatusIcon"
        src="@/assets/payment/success.svg"
        alt="payment successful"
      />
      <div class="paymentStatusTitle">Payment Successful</div>
      <div class="paymentStatusInfo">
        The payment has been done successfully.<br />Thanks for being there with
        us.
      </div>
      <!-- <div class="successBanner" v-if="!this.isTestCustomerId">
        <div class="font-bold">What's the Next:&nbsp;</div>
        <div class="bannerMessage">
          We will send your kit as soon as possible
        </div>
      </div> -->

      <!-- temporary hide for express V2 beta 1 launch - general_beta1 -->
      <!-- <div
        class="redirectBanner"
        v-if="this.showNextStep"
        @click="goToNextStep()"
      >
        <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div> -->
      <!-- temporary hide for express V2 beta 1 launch - general_beta1 ---- end-->

      <!-- temporary use for express V2 beta 1 launch - general_beta1 -->
      <div
        class="redirectBanner"
        v-if="this.addressNeeded && this.showNextStep"
        @click="goToNextStep('address')"
      >
        <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div>
      <div
        class="redirectBanner"
        v-if="!this.addressNeeded && this.bloodDrawNeeded && this.showNextStep"
        @click="goToNextStep('bloodDraw')"
      >
      <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div>
      <div
        class="redirectBanner"
        v-if="!this.addressNeeded && !this.bloodDrawNeeded && this.showNextStep"
        @click="goToNextStep('collectionTutorial')"
      >
        <div class="font-bold">Go to the Next Step&nbsp;</div>
      </div>
      <!-- temporary use for express V2 beta 1 launch - general_beta1 end -->
    </div>
  </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState } from "pinia";
export default {
  name: "PaymentSucceed",
  data() {
    return {
      token: localStorage.getItem("pnsToken"),
      sampleId: localStorage.getItem("sample_id"),
      customerId: localStorage.getItem("customer_id"),
      accessionId: localStorage.getItem("accession_id"),
      patientId: localStorage.getItem("patient_id"),
      chargeMethod: localStorage.getItem("charge_method"),
      addressNeeded: localStorage.getItem("address_needed") == "true",
      bloodDrawNeeded: localStorage.getItem("blood_draw_needed") == "true",
      conciergeNeeded: localStorage.getItem("concierge_needed") == "true",
      showSucceedPage: false,
      payFixedAmount: localStorage.getItem("amount_to_pay") ? true : false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (
      this.billingInfo &&
      Number(this.billingInfo.concierge_fee) > 0 &&
      this.conciergeNeeded &&
      !this.payFixedAmount
    ) {
      const redirectUrl = `https://phleb.vibrant-wellness.com/app/concierge-request/concierge/${this.accessionId}/confirmPage?payment=success`;
      window.location.href = redirectUrl;
      return;
    }
    this.showSucceedPage = true;
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      // temporary use for express V2 beta 1 launch - general_beta1
      "express_v2_beta_1"
    ]),
    showNextStep() {
      return (
        this.billingInfo &&
        Number(this.billingInfo.concierge_fee) <= 0 &&
        !this.payFixedAmount
      );
    },
    isTestCustomerId() {
      return (
        this.customerId == 999997 ||
        this.customerId == 29520 ||
        this.customerId == 29818 ||
        this.customerId == 31157 ||
        this.customerId == 12005 ||
        this.customerId == 1000002
      );
    },
  },
  methods: {
    goToNextStep(step) {
      // temporary use for express V2 beta 1 launch - general_beta1
      if(this.express_v2_beta_1){
        this.$router.push(
            `uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}&address_needed=${this.addressNeeded}`
        );
      }else{
        switch (step) {
          case "address":
            // redirectUrl = `https://pns.vibrant-wellness.com/uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}`;
            this.$router.push(
              `uploadShippingAddress?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&bloodDrawNeeded=${this.bloodDrawNeeded}&patient_id=${this.patientId}`
            );
            break;
          case "bloodDraw":
            // redirectUrl = `https://pns.vibrant-wellness.com/bloodDraw?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&patient_id=${this.patientId}`;
            this.$router.push(
              `bloodDraw?customer_id=${this.customerId}&sample_id=${this.sampleId}&accession_id=${this.accessionId}&token=${this.token}&patient_id=${this.patientId}`
            );
            break;
          // NOTE: currently this case "collectionTutorial" is restrcited by other upper-level business logic (order test): all non-blood kits will be sent to patient
          // so if it is nonblood kit: it will assurely go to case "address"
          case "collectionTutorial":
            // redirectUrl = "https://www.vibrant-wellness.com/collection/";
            window.location.href = "https://www.vibrant-wellness.com/collection/";
            break;
        }
      }
      
      
    },
  },
};
</script>

<style scoped>
.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  position: relative;
}

.successBanner {
  width: 767px;
  height: 80px !important;
  border-radius: 4px;
  background: #20a8c3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
}

.redirectBanner {
  width: 767px;
  height: 80px !important;
  border-radius: 4px;
  background: #20a8c3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.redirectBanner:hover {
  background: #30c0db;
}

@media only screen and (max-width: 767px) {
  .successBanner {
    width: 320px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .redirectBanner {
    width: 320px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .successBanner .bannerMessage::before {
    content: "\A";
  }
}
</style>
