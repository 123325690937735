<template>
    <div class = "ops-btns-container">
        <div v-if = " !incomplete_information && !has_change_action && !is_confirmed_or_saved_before" 
        :class = "['save-address-btn',{'full-width':need_blood_draw==null||incomplete_information}]"
        @click = "directConfirmShippingAddressWithoutChange" v-loading="confirm_info_loading">
            Confirm
        </div>
        <div v-else-if = "incomplete_information || (!incomplete_information && has_change_action)"
        :class = "['save-address-btn',{'disable-btn': !allRequiedFieldsFilled},{'full-width':need_blood_draw==null||incomplete_information}]" 
        @click = 'saveShippingAddress' v-loading="confirm_info_loading">
            Save
        </div>

        <div v-if = "need_blood_draw!=null && !incomplete_information" 
        :class = "[
            'cancel-btn',
            {'full-width':need_blood_draw!=null && !incomplete_information && is_confirmed_or_saved_before && !has_change_action}
        ]"
        @click = "cancelShippingAddress">
            Skip
        </div>
    </div>
    <DuplicatedPatientDialog v-if= "duplicatedPatientDialogVisible"
    @close-by-click-confirm="handleCloseDuplicatedPatientDialogByClickConfirm()"
    @close-by-click-contact-us="handleCloseDuplicatedPatientDialogByClickContactUs()" 
    @close = "duplicatedPatientDialogVisible=false"/>
    <!-- <el-dialog
        v-model="confirmAddressDialogVisible"
        class = 'confirm-address-dialog'
        top = '20vh'
    >
        <template #header = '{close}'>
            <div class = 'dialog-header-content'>
                <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
        <div class  = 'confirm-address-dialog-content'>
            <div class = 'confirm-address-dialog-title'>
                Please confirm that the address you entered is correct!
            </div>
            <div class = "current-shipping-address-summary">
                <div class = "summary-row">
                    <div class = 'summary-text'>
                        Name
                    </div>
                    <div class = 'summary-text'>
                        {{this.current_receiver}}
                    </div>
                </div>
                <div class = 'summary-divider'></div>
                <div class = "summary-row">
                    <div class = 'summary-text'>
                        Address
                    </div>
                    <div class = 'summary-text'>
                        {{this.current_address_concatenated}}
                    </div>
                </div>
            </div>
            <div class = "confirm-ops-btns-div">
                <div class = "edit-btn" @click="clickEditBtn">Edit</div>
                <div class = "confirm-btn" @click="clickConfirmBtn">Confirm</div>
            </div>
        </div>
    </el-dialog> -->
</template>

<script>
import DuplicatedPatientDialog from "@/components/shippingAddress/DuplicatedPatientDialog.vue";
import { mapState, mapActions } from 'pinia';
import { useShippingAddressStore } from "@/stores/ShippingAddressStore.ts";
export default{
    components:{
        DuplicatedPatientDialog,
    },
    async beforeUnmount(){
        console.log("shipping inputs beforeUnmount", this.edits_saved);
        // record significant address change actions, no matter save or not
        if(this.edits_saved){
            if(this.is_address_change_significant_before_confirmed){
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','significant-change-address-action', 1);
            }
        }else{
            const is_address_change_significant = await this.checkWhetherAddressChangeIsSignificant();
            if(is_address_change_significant){
                console.log("shipping inputs beforeUnmount record significant address");
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','significant-change-address-action', 1);
            }
        }
        
        // record address change actions, no matter save or not (including only check apply-to-all box)
        if(this.has_change_address_action){
            if(this.incomplete_address_in_db){
                //matomo track 0-1 input
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','0-1-change-address-action', 1);
            }else{
                //matomo track 1-1.1 change
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-change-address-action', 1);
            }
        }
        // record checking apply-to-all box (only for 1-1.1)
        if(!this.incomplete_address_in_db && !this.is_current_shipping_address_same_with_patient_profile_address && this.is_apply_to_future_order){
            //matomo track 1-1.1 checkbox action
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-check-apply-to-all-action', 1);
        }
    },
    data(){
        return{
            confirmAddressDialogVisible:false,
            duplicatedPatientDialogVisible:false,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'address_needed',
            'previous_shipping_address_data',
            'current_shipping_address_data',
            'required_input_fields',
            'need_blood_draw',
            'edits_saved',
            'is_confirmed_or_saved_before',
            'is_address_change_significant_before_confirmed',
            'incomplete_address_in_db',
            'incomplete_basic_information_in_db',
            'is_apply_to_future_order',
            'is_current_shipping_address_same_with_patient_profile_address',
            'confirm_info_loading',
        ]),
        allRequiedFieldsFilled(){
            for(const requied_field of this.required_input_fields){
                if(!this.current_shipping_address_data[requied_field]){
                    return false;
                }
            }
            return true;
        },
        current_address_concatenated(){
            return `${this.current_shipping_address_data.street}, ${this.current_shipping_address_data.city}, ${this.current_shipping_address_data.state} ${this.current_shipping_address_data.zipcode}, ${this.current_shipping_address_data.country}`;
        },
        current_receiver(){
            return this.current_shipping_address_data.name;
        },
        incomplete_information(){
            //only address == false, will no address section; default address_needed is null and will show address section.
            return this.address_needed == false ? this.incomplete_basic_information_in_db : (this.incomplete_address_in_db || this.incomplete_basic_information_in_db);
        },
        has_change_address_action(){
            const prev_shipping_address = `${this.previous_shipping_address_data.street}, ${this.previous_shipping_address_data.city}, ${this.previous_shipping_address_data.state} ${this.previous_shipping_address_data.zipcode}, ${this.previous_shipping_address_data.country}`;
            const prev_receiver = this.previous_shipping_address_data.name;
            //if is_current_shipping_address_same_with_patient_profile_address == true:
            //we won't see is_apply_to_future_order's value. Default treat it to be false;
            return prev_shipping_address != this.current_address_concatenated || prev_receiver != this.current_receiver || (!this.is_current_shipping_address_same_with_patient_profile_address && this.is_apply_to_future_order);
        },
        has_change_basic_information_action(){
            return this.previous_shipping_address_data.gender != this.current_shipping_address_data.gender || this.previous_shipping_address_data.birthdate != this.current_shipping_address_data.birthdate || this.previous_shipping_address_data.phone != this.current_shipping_address_data.phone;
        },
        has_change_action(){
            //only address == false, will no address section; default address_needed is null and will show address section. 
            return this.address_needed==false ? this.has_change_basic_information_action : (this.has_change_address_action || this.has_change_basic_information_action);
        },
        whether_check_duplicated_patient(){
            // check duplicated patient if name, gender, DOB changed
            // unneccessary to check name as it is always disabled.
            return this.previous_shipping_address_data.gender != this.current_shipping_address_data.gender || this.previous_shipping_address_data.birthdate != this.current_shipping_address_data.birthdate;
        },
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'confirmShippingAddress',
            'updateEditsSaved',
            'goToNextStep',
            'checkWhetherAddressChangeIsSignificant',
            'updateShowContactSupportToSolveDuplicatedPatient',
            'getDuplicatedPatient'
        ]),
        cancelShippingAddress(){
            this.goToNextStep(this.$router);
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-skip-button', 1);
        },
        directConfirmShippingAddressWithoutChange(){
            //call backend to confirm
            this.confirmShippingAddress();
            // this.goToNextStep(this.$router);
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','direct-confirm-shipping-without-chnange', 1);
        },
        async saveShippingAddress(){
            if(this.allRequiedFieldsFilled){
            //---------------- without address double check popup ------------------------------------------
                //check duplicated patient if whether_check_duplicated_patient =true (name, gender, or DOB changed)
                if(this.whether_check_duplicated_patient){
                    try{
                        //1. call backend to get the info of possible duplicated paitent:
                        const res = await this.getDuplicatedPatient();
                        //-> if there is an info object of possible duplicated paitent: res != null
                        //-> then show the duplicatedPatientDialog to display info and ask whether to merge patient
                        if(res){
                            this.duplicatedPatientDialogVisible = true;
                        }else{
                        //-> else: directly go 'save' workflow 
                            this.confirmShippingAddress();
                        }
                    }catch(err){
                        //run here if getDuplicatedPatient function return an error or other error occurs in the following steps.
                        console.log('getDuplicatedPatient and open dialog error', err);
                    }
                }else{
                //if no need to check duplicated patient: 
                //-> directly go 'save' workflow 
                    //if has_change_action: call backend to save and confirm information
                    //(in current flow: save btn only appears and workable when has_changed_actions is true):
                    //case 1: incomplete address in db -> filled all inputs -> has_changed_actions = true
                    //case 2 : complete address in db -> changed some inputs -> has_changed_actions = true
                    if(this.has_change_action){
                        this.confirmShippingAddress();
                    }else{
                    //else: no need to call backend, then directly show 'edit successfully'
                    //(in current flow: this condition is not possible)
                    //write for better business logic and future maintainance
                        this.updateEditsSaved(true);
                    }
                }
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-save-button', 1);
            }
        },
        // isAddressOrBasicInformationChanged(){
        //     // const prev_shipping_address = `${this.previous_shipping_address_data.street}, ${this.previous_shipping_address_data.city}, ${this.previous_shipping_address_data.state} ${this.previous_shipping_address_data.zipcode}, ${this.previous_shipping_address_data.country}`;
        //     // const prev_receiver = this.previous_shipping_address_data.name;
        //     // return prev_shipping_address != this.current_address_concatenated || prev_receiver != this.current_receiver || this.is_apply_to_future_order;
        //     return this.has_change_address_action || this.has_change_basic_information_action;
        // },
        handleCloseDuplicatedPatientDialogByClickConfirm(){
            //close the dialog 
            this.duplicatedPatientDialogVisible = false;
            //ask parent component to save the current info
            this.confirmShippingAddress();
        },
        handleCloseDuplicatedPatientDialogByClickContactUs(){
            //close the dialog 
            this.duplicatedPatientDialogVisible = false;
            //page becomes the one with pending icon and suggestion of contacting wording
            this.updateShowContactSupportToSolveDuplicatedPatient(true);
        },
        clickConfirmBtn(){
            this.confirmAddressDialogVisible = false;
            if(this.has_change_action){
                this.confirmShippingAddress();
            }else{
                this.updateEditsSaved(true);
            }
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-confirm-button', 1);
        },
        clickEditBtn(){
            this.confirmAddressDialogVisible = false;
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-edit-button', 1);
        }
    }
}
</script>

<style scoped>
.ops-btns-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding-left:314px;
    padding-right:318px;
}
.save-address-btn{
    margin-top:36px;
    padding:8px 0px;
    width:calc(50% - 10px);
    background: #0E4672;
    border-radius: 4px;

    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.429px;

    cursor:pointer;
    position: relative;
    z-index: 1;
}
.full-width{
    width:100% !important;
}
.disable-btn{
    color:#BBB !important;
    background:#F3F3F3 !important;
    cursor: not-allowed !important;
}
.cancel-btn{
    width: calc(50% - 10px);
    margin-top: 36px;
    text-align: center;
    border-radius: 4px;
    background:#FFF;
    padding:7px 0px;
    border: 1px solid #0E4672;
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.429px;

    cursor:pointer;
    position: relative;
    z-index: 1;
}
.dialog-header-content{
    display:flex;
    justify-content:flex-end;
    padding-right:24px;
    padding-top:24px;
    padding-bottom:58px;
}
.close-icon{
    cursor:pointer;
}
.confirm-address-dialog-content{
    padding-left: 96px;
    padding-right:96px;
    padding-bottom:106px;

    display:flex;
    flex-direction:column;
    gap:60px;
}

.confirm-address-dialog-title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.458px;
}

.summary-row{
    display:flex;
    justify-content:space-between;
    gap:35px;
}
.summary-text{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4px;
}
.summary-divider{
    margin-top:12px;
    margin-bottom:16px;
    display:block;
    content:'';
    height:1px;
    background: #0E4672;

}
.confirm-ops-btns-div{
    display:flex;
    justify-content:center;
    gap:16px;
}
.edit-btn{
    padding: 12px 0px;
    width:27%;
    min-width:132px;
    border-radius: 4px;
    border: 1px solid #0E4672;
    text-align:center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.confirm-btn{
    padding: 13px 0px;
    width:27%;
    min-width:132px;
    border-radius: 4px;
    background:#0E4672;
    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
@media only screen and (max-width: 1260px){
    .ops-btns-container{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        padding-left:157px;
        padding-right:159px;
    }
    .confirm-address-dialog-content{
        padding-left: 48px;
        padding-right:48px;
        padding-bottom:75px;
        gap:40px;
    }
}

@media only screen and (max-width: 767px){
    .ops-btns-container{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        gap:12px;
        padding-left:28px;
        padding-right:27px;
    }
    .dialog-header-content{
        display:flex;
        justify-content:flex-end;
        padding-right:18px;
        padding-top:18px;
        padding-bottom:18px;
    }
    .confirm-address-dialog-content{
        padding-left: 24px;
        padding-right:24px;
        padding-bottom:46px;
        gap:24px;
    }
    .confirm-address-dialog-title {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .summary-text {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
    }
    .summary-divider {
        margin-top: 12px;
        margin-bottom: 16px;
    }
    .save-address-btn{
        margin-top:24px;
        padding:8px 0px;
        width:100%;
        background: #0E4672;
        border-radius: 4px;

        text-align:center;

        color: #FFF;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 26px; /* 173.333% */
        letter-spacing: 0.429px;

        cursor:pointer;
        position: relative;
        z-index: 1;
    }
    .cancel-btn{
        margin-top:24px;
        padding:7px 0px;
        width:100%;
        border: 1px solid #0E4672;
        background:#FFF;
        border-radius: 4px;

        text-align:center;

        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 26px; /* 173.333% */
        letter-spacing: 0.429px;

        cursor:pointer;
        position: relative;
        z-index: 1;
    }
}
</style>