import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
//configure Apollo Client
const httpLink = createHttpLink({
    uri: 'https://www.vibrant-america.com/lisapi/v1/lis/calendar-staging/graphql',
  });
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default apolloClient;