import wrapURL from './interceptors';

export const baseURL = wrapURL(
    process.env.VUE_APP_ENV=='production' ? 'https://www.vibrant-america.com/lisapi/v1/portal/trans-service':'https://www.vibrant-america.com/lisapi/v1/portal/trans-service-st'
);

export const countryLisBaseURL = wrapURL('https://www.vibrant-america.com/lisapi/v1/portal/order/orderTest/getCountryList');

export const inventoryBaseURL = wrapURL(
    process.env.VUE_APP_ENV=='production' ? 'https://www.vibrant-america.com/lisapi/v1/lis/inventory-prod-http':'https://www.vibrant-america.com/lisapi/v1/lis/inventory-dev-http'
);

export const coreServiceBaseURL = wrapURL(
    process.env.VUE_APP_ENV == "production" ? "https://www.vibrant-america.com/lisapi/v1/lis/lis-core-service/api" : "https://www.vibrant-america.com/lisapi/v1/lis/lis-corestaging-service/api"
);

export const sampleBaseURL = wrapURL(
    process.env.VUE_APP_ENV == "production" ? "https://www.vibrant-america.com/lisapi/v1/lis/sample/" : "https://www.vibrant-america.com/lisapi/v1/lis/sample-dev/"
);